import _ from 'lodash';
import { getNodeAndPathOfDirectory } from '~/src/common'
import Project_API from "~/service/Ged/Project.js";
import Project_db from "~/db_access/Project";
import Rapport_API from "~/service/Rapport/Rapport.js";
import Dexie from "dexie";
import download from "downloadjs";
import {exportDB, importInto} from "dexie-export-import";
const Antd = require("ant-design-vue");

export default {
	async exportDb() {
        const db = await new Dexie('axxone_db').open();

		const blob = await exportDB(db, {prettyJson: true});
		download(blob, "mc-file-export.json", "application/json");
    },
	async importDb({ dispatch }, blob) {
        const db = await new Dexie('axxone_db').open();
		
		await importInto(db, blob, {noTransaction: true, clearTablesBeforeImport: true});
    },
	async init_with_online_reports({ dispatch, commit, getters, rootGetters }) {
		if (getters.getActiveModule != "RAPPORT") {
			return;
		}
		commit('CHANGE_INIT_LOADING_PERCENT', 0);

		let modeles = await Rapport_API.get_all_modeles();
		commit('CHANGE_INIT_LOADING_PERCENT', 10);
		
		let actors = await Project_API.get_actors();
		commit('project/CHANGE_ACTORS', actors, { root: true });
		commit('CHANGE_INIT_LOADING_PERCENT', 20);
		
		await dispatch('changeDisplayState', '1')
		//await Rapport_API.get_last_rapport_by_modele()
		commit('CHANGE_INIT_LOADING_PERCENT', 30);
		
		let project = rootGetters["project/getProject"]
		let company = rootGetters["company/getCompany"]
		Project_db.register_infos_project({ company, project, actors, modeles })
		commit('CHANGE_INIT_LOADING_PERCENT', 60);
		
		dispatch('rapports/update_editing_reports_from_server_to_local');
		commit('CHANGE_INIT_LOADING_PERCENT', 80);
		
		await dispatch('rapports/display_online_validate_reports');
		commit('CHANGE_INIT_LOADING_PERCENT', 100);
	},
	async init_with_offline_reports({ commit, dispatch, getters }) {
		if (getters.getActiveModule != "RAPPORT") {
			return;
		}
		let actors = await Project_db.get_actors();
		commit('project/CHANGE_ACTORS', actors, { root: true });
		
		await dispatch('changeDisplayState', '1');
		
		await dispatch('rapports/display_offline_validate_reports');
	},
	async init_with_offline_files({ dispatch, commit, getters, rootGetters }) {
		if (getters.getActiveModule != "GED") {
			return;
		}
		commit('CHANGE_INIT_LOADING_PERCENT', 0);
		commit('arborescence/CHANGE_ARBO_OPEN', false, { root: true });
		commit('CHANGE_INIT_LOADING_PERCENT', 5);
		commit('arborescence/CHANGE_ARBO_ACTIVE', { displayState: "3", boolean: true }, { root: true });
		commit('CHANGE_INIT_LOADING_PERCENT', 30);
		await dispatch('arborescence/updateArbosTodisplay', { root: true });
		commit('CHANGE_INIT_LOADING_PERCENT', 50);
		await dispatch('changeDisplayState', '3')
		commit('CHANGE_INIT_LOADING_PERCENT', 60);
		dispatch('updateFilesAndDirectoryToDisplay', { selectedKeyDirectory: rootGetters["arborescence/getTreeData"][0].key })
		commit('CHANGE_INIT_LOADING_PERCENT', 100);
	},
	async init_with_online_files({ dispatch, commit, getters, rootGetters }) {
		if (getters.getActiveModule != "GED") {
			return;
		}
		commit('CHANGE_INIT_LOADING_PERCENT', 0);
		commit('project/CHANGE_ACTORS', await Project_API.get_actors(), { root: true });
		let actors = await Project_API.get_actors();
		let project = rootGetters["project/getProject"]
		let company = rootGetters["company/getCompany"]
		Project_db.register_infos_project({ company, project, actors })
		commit('CHANGE_INIT_LOADING_PERCENT', 10);
		commit('arborescence/CHANGE_ARBO_OPEN', false, { root: true });
		commit('CHANGE_INIT_LOADING_PERCENT', 20);
		commit('arborescence/CHANGE_ARBO_ACTIVE', { displayState: "1", boolean: true }, { root: true });
		commit('CHANGE_INIT_LOADING_PERCENT', 40);
		await dispatch('arborescence/updateArbosTodisplay', { startingAction: true }, { root: true });
		commit('CHANGE_INIT_LOADING_PERCENT', 80);
		await dispatch('changeDisplayState', '1');
		commit('CHANGE_INIT_LOADING_PERCENT', 90);
		await dispatch("directories/changeSelectedDirectory", rootGetters["arborescence/getTreeData"][0], { root: true });
		commit('CHANGE_INIT_LOADING_PERCENT', 100);
	},
	async updateFilesAndDirectoryToDisplay({ commit, dispatch, rootGetters }, { selectedKeyDirectory, page = 1 }) {
		await dispatch('directories/resetDirectoriesToDisplay');
		await dispatch('files/resetFilesToDisplay');
		let nodeAndPath = await getNodeAndPathOfDirectory(
			rootGetters["arborescence/getTreeData"][0],
			selectedKeyDirectory
		);

		//On ne change que le currentPath et le dossier selectionner si le nouveau noeud a été trouvé. Si il n'existe pas, c'est qu'il faut rester sur l'ancienne valeur (ex retour de rapport)
		if (nodeAndPath.node !== "") {
			dispatch('directories/setCurrentPath', _.takeRight(nodeAndPath.path, 1), { root: true });
			await dispatch("directories/changeSelectedDirectory", nodeAndPath.node, { root: true });
		}


		await dispatch('directories/updateDirectoriesToDisplay', { root: true });
		setTimeout(async () => {
			dispatch('files/updateFilesToDisplay', { selectedKeyDirectory: selectedKeyDirectory, page: page }, { root: true });
		}, 500);
	},
	changeDisplayState({ commit }, number) {
		commit("CHANGE_DISPLAY_STATE", number);
	},
	createLoader({ commit }, steps) {
		console.log("creation loader " + steps[0])
		let loaders = [];
		for (var i = 0; i < steps.length; i++) {
			loaders.push({ rang: i, loadingPercent: 0, value: steps[i], linked: false })
		}
		commit('CREATE_LOADERS', loaders);
	},
	updateLoader({ commit }, { rang, loadingPercent }) {
		commit('UPDATE_LOADERS', { rang, loadingPercent })
	},
	getNewLoaderAvailable({ commit, getters }) {
		let newLoader = _.find(getters.getLoaders, { linked: false });
		if (newLoader != undefined) {
			commit('LINK_LOADER', newLoader.rang)
		}
		return newLoader;
	},
	changeLoadingPercent({ commit }, { loaderLinked, loadingPercent }) {
		commit('CHANGE_LOADING_PERCENT', { loaderLinked, loadingPercent });
	},
	changeOnline({ commit }, value) {
		commit('CHANGE_ONLINE', value);
	},
	resetLoaders({ commit }) {
		commit('RESET_LOADERS');
	},
	changeActiveModule({ commit }, module) {
		commit('CHANGE_ACTIVE_MODULE', module);
	},
	changeInitLoadingPercent({ commit }, value) {
		commit('CHANGE_INIT_LOADING_PERCENT', value)
	},
	changeUpdate({ commit, dispatch }, { type, value }) {
		commit('CHANGE_UPDATE', { type, value });
		if (type == 'UPDATE') {
			let string = "<div class='button'>Cliquez pour installer la nouvelle version<div>";
			Antd.notification.open({
				message: "Mise à jour disponible",
				description: h => {
					return h(
						'div', { domProps: { innerHTML: string } });
				},
				class: "alert-update",
				duration: 0,
				async onClick() {
					dispatch('loadUpdate');
				}
			});
		}
	},
	resetUpdate({ commit }) {
		commit('CHANGE_UPDATE', { type: "", value: "" });
	},
	notifUpdate({ commit, dispatch }) {
		commit('CHANGE_UPDATE', { type: "", value: "" });
		commit('CHANGE_UPDATE_OK', true)
		window.location.reload()
	},

	changeUpdateOk({ commit }, value) {
		if (!value) {
			Antd.notification["success"]({
				message: "Mise à jour installée avec succès",
				description: "Vous pouvez continuer a utiliser l'application.",
			});
		}
		commit('CHANGE_UPDATE_OK', value)
	},


	async loadUpdate({ dispatch, getters }) {
		if (getters.getUpdate !== {} && getters.getUpdate.type == "UPDATE") {
			await getters.getUpdate.value.messageSW({ type: 'UPDATE' })
		}
	},
	changeViewableFileId({ commit }, value) {
		commit('CHANGE_NOT_RELOAD', value);
	},

	changeLastRouteName({ commit }, value) {
		commit('CHANGE_LAST_ROUTE_NAME', value);
	},
	changeMustReconnect({ commit }, value) {
		if (value) {
			Antd.notification["warning"]({
				message: "Mode en ligne accessible",
				description: "Pour accéder aux fonctionnalités en ligne, merci de vous reconnecter.",
			});
		}
		commit('CHANGE_MUST_RECONNECT', value);
	},

	addInterruptCurrentLoading({ commit, state }, value) {
		commit('ADD_INTERRUPT_CURRENT_LOADING', value);
	},
	rmInterruptCurrentLoading({ commit }, value) {
		commit('RM_INTERRUPT_CURRENT_LOADING', value);
	},
	async updateStockagePercent({ commit }) {
		let percentUsed = 0;
		if ('storage' in navigator && 'estimate' in navigator.storage) {
			let rate = await navigator.storage.estimate();
			percentUsed = Math.round(rate.usage / rate.quota * 100);
			// console.log(rate)
		} else {
			percentUsed = "Err";
		}
		// commit('CHANGE_STOCKAGE_PERCENT',percentUsed);
	}
}
