import store from '~/src/store/';
import { get, post } from '~/service/Rapport/service.js';
import _ from 'lodash'

const Rapport_API = {
    async get_list_compte_rendus_valides(project_id) {
        return new Promise(function (resolve) {
            get('projects/' + project_id + '/validate_report', null, function (result) {
                resolve(result);
            });
        });
    },
    async send_report(project_id, rapport_id, text_mail, object_mail) {
        return new Promise(function (resolve) {
            post('projects/' + project_id + '/validate_report/' + rapport_id, { object_mail: object_mail, text_mail: text_mail }, function (result) {
                resolve(result);
            });
        });
    },
    async get_previsualisation(project_id, rapport_id) {
        return new Promise(function (resolve) {
            get('projects/' + project_id + '/preview_report/' + rapport_id, null, async function (res) {
                let data = await Rapport_API.read_stream(res.body.getReader());
                console.log(data)
                resolve(data);
            }, true, false);
        });
    },
    read_stream(reader) {
        let tempData = new Uint8Array();
        return new Promise((resolve) => {
            new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(async ({ done, value }) => {
                            if (done) {
                                controller.close();
                                resolve(tempData);
                            }
                            controller.enqueue(value);
                            let a = new Uint8Array(tempData.length + value.length);
                            a.set(tempData);
                            a.set(value, tempData.length);
                            tempData = a;
                            return pump();
                        });
                    }
                }
            })
        })
    },
    synchronise_rapport(rapports) {
        return new Promise((resolve) => {
            post('cr/responses/save', rapports,
                function (res) {
                    console.log(res);
                    resolve(res);
                });
        });
    },
    async synchronise_unvalidate_report(rapports = [], project_id = store.state.project.project.id) {
        return new Promise(function (resolve) {
            post('projects/' + project_id + '/unvalidate_report', rapports, async function (result) {
                resolve(result);
            });
        });
    },
    async get_all_modeles(project_id = store.state.project.project.id) {
        return new Promise(function (resolve) {
            get('projects/' + project_id + '/cr/modeles/all', null, async function (result) {
                resolve(result);
            });
        });

    },
}
export default Rapport_API;