import Vue from 'vue'
import Router from 'vue-router'
import store from '~/src/store/';
import ConnexionScreen from '~/src/components/ConnexionScreen'
import CompanySelectionScreen from '~/src/components/CompanySelectionScreen'
import EspaceSelectionScreen from '~/src/components/EspaceSelectionScreen'
import FileScreen from '~/src/components/FileScreen'
import RapportEditScreen from '~/src/components/RapportEditScreen'
import RapportScreen from '~/src/components/RapportScreen'
import ModeleRapportSelectionScreen from '~/src/components/ModeleRapportSelectionScreen'
import ViewerScreen from '~/src/components/ViewerScreen'
import ActorScreen from '~/src/components/ActorScreen'

// /!\ pas de store.getters dans les Meta


Vue.use(Router)

export default new Router({
    routes: [
        {
            path: "/",
            redirect: {
                name: "Connexion"
            }
        },
        {
            path: '/login',
            name: 'Connexion',
            component: ConnexionScreen,
        },
        {
            path: '/company',
            name: 'CompanySelection',
            component: CompanySelectionScreen,
            meta: {
                requiresAuth: true,
                offline_trigger:[{action:'company/updateCompanies'}],
                online_trigger:[{action:'company/updateCompanies'}]
            },
            beforeEnter(to, from, next) {
                store.commit("CHANGE_LAST_ROUTE_NAME", from.name);
                next();
            },
        },
        {
            path: '/espace',
            name: 'EspaceSelection',
            component: EspaceSelectionScreen,
            meta: {
                requiresAuth: true,
                offline_trigger:[{action:'project/updateProjects'}],
                online_trigger:[{action:'project/updateProjects'}]
            },
        },
        {
            path: '/file',
            name: 'File',
            component: FileScreen,
            meta: {
                requiresAuth: true,
                offline_trigger:[{action:'changeDisplayState',value:3},{action:'arborescence/updateArbosTodisplay'}],
                online_trigger:[{action:'arborescence/updateArbosTodisplay'}]
            },
            beforeEnter(to, from, next) {
                store.commit("CHANGE_LAST_ROUTE_NAME", from.name);
                next();
            },
        },
        {
            path: '/rapport',
            name: 'Rapport',
            component: RapportScreen,
            meta: {
                requiresAuth: true,
                offline_trigger:[{action:'rapports/refresh_display_reports'}],
                online_trigger:[{action:'rapports/refresh_display_reports'}],
            },
        },
        {
            path: '/viewer',
            name: 'Viewer',
            component: ViewerScreen,
            meta: {
                requiresAuth: true,
                offline_trigger:[{action:'init_with_offline_files'},{action:'init_with_offline_reports'}],
                online_trigger:[{action:'init_with_online_files'},{action:'init_with_online_reports'}]
            },
        },
        {
            path: '/nouveau-rapport',
            name: 'NewRapport',
            component: ModeleRapportSelectionScreen,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/edit-rapport',
            name: 'EditRapport',
            component: RapportEditScreen,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/actors',
            name: 'Actors',
            component: ActorScreen,
            meta: {
                requiresAuth: true
            },
        },
    ],
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {selector: to.hash}
        } else {
            return { x: 0, y: 0 }
        }
    }
})