<template>
    <a-table
        :columns="columnsRapports"
        :data-source="rapportsInProgress"
        :pagination="false"
        :showHeader="false"
        tableLayout="fixed"
        style="cursor: pointer"
        :customRow="custom_row_rapport"
        rowKey="id"
    >
        <span slot="name" slot-scope="rapport" style="display: inline-flex">
            <IconeDocRenderer
                :type="rapport.format.toUpperCase()"
                styleImage="width:25px;margin-right:10px"
            />{{ rapport.lib }}</span
        >
        <span slot="actions" slot-scope="rapport" @click="blockRow = true">
            <a-icon
                v-if="is_rapport_syncing(rapport.header.project_id, rapport.id) || cloneInProgress.includes(rapport.id)"
                style="font-size: 25px"
                type="sync"
                spin
            />
            <div v-else>
                <a-tooltip placement="topLeft">
                    <template slot="title">{{ $t("duplicate.tooltip_text") }}</template>
                    <a-icon
                        v-if="can_clone(rapport)"
                        style="font-size: 25px; padding: 5px"
                        type="copy"
                        @click="cloneCurrentReport(rapport)"
                    />
                </a-tooltip>
                <a-icon
                    v-if="can_deleted(rapport)"
                    style="font-size: 25px; padding: 5px"
                    type="delete"
                    @click="
                        confirmDelete({
                            modele_id: rapport.modele_id,
                            rapport_id: rapport.id,
                        })
                    "
                />
            </div>
        </span>
    </a-table>
</template>
<script>
import IconeDocRenderer from "../renderer/IconeDocRenderer";
import { mapGetters, mapActions } from "vuex";
import { includes, toUpper, remove } from 'lodash';

export default {
    i18nOptions: {
        keyPrefix: "rapportselection",
    },
    name: "RapportsListInProgress",
    components: {
        IconeDocRenderer,
    },
    props: {
        rapportsInProgress: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            blockRow: false,
            cloneInProgress: [],
            columnsRapports: [],
            status_updatable: [
                "RÉDACTION",
                "BLOQUER",
                "SIGNATURE",
            ],
            status_clonable: [
                "RÉDACTION",
                "BLOQUER",
                "SIGNATURE",
                "ENVOI",
            ],
        };
    },
    mounted() {
        this.columnsRapports = [
            {
                title: "Name",
                key: "name",
                scopedSlots: { customRender: "name" },
                width: "20%",
            },
            {
                title: "Organisme",
                dataIndex: "org_name",
                key: "org_name",
                scopedSlots: { customRender: "organisme" },
                elipsis: true,
                width: "60%",
            },
            {
                title: "Actions",
                key: "actions",
                scopedSlots: { customRender: "actions" },
                width: "20%",
                align: "right",
            },
        ];
    },
    methods: {
        ...mapActions("files", ["setFileToView"]),
        ...mapActions("rapports", [
            "load_editing_report",
            "clone_report",
            "delete_editing_report",
            "update_editing_reports_from_local_to_server",
            "refresh_display_reports",
        ]),
        is_rapport_syncing(project_id, rapport_id) {
            for (let i = 0; i < this.in_progress.length; i++) {
                const project = this.in_progress[i];
                if (project.project_id === project_id) {
                    return project.rapport.includes(rapport_id);
                }
            }

            return false;
        },
        acces_rapport(rapport) {
            this.load_editing_report({
                modele_id: rapport.modele_id,
                rapport_id: rapport.id,
            }).then(() => {
                this.$router.push({ name: "EditRapport" });
            });
        },
        custom_row_rapport(record) {
            return {
                on: {
                    click: (event) => {
                        setTimeout(() => {
                            if (!this.blockRow && includes(this.status_updatable, toUpper(record.format))) {
                                this.acces_rapport(record);
                            } else {
                                this.blockRow = false;
                            }
                        }, 50);
                    },
                },
            };
        },
        reload() {
            this.refresh_display_reports();
        },
        async delete_rapport_en_cours({ modele_id, rapport_id }) {
            await this.delete_editing_report({
                project_id: this.getProjectId,
                modele_id,
                rapport_id,
            }).then(() => {
                this.update_editing_reports_from_local_to_server().then(() => {
                    this.reload();
                });
            });
        },
        confirmDelete({ modele_id, rapport_id }) {
            this.blockRow = true;
            this.$confirm({
                title: this.$t("confirm_remove"),
                content: this.$t("info_remove"),
                okText: this.$i18n.i18next.t("common.confirm.yes"),
                okType: "danger",
                cancelText: this.$i18n.i18next.t("common.confirm.no"),
                onOk: () => {
                    this.delete_rapport_en_cours({ modele_id, rapport_id });
                },
                onCancel() {},
            });
        },
        async cloneCurrentReport(rapport) {
            this.blockRow = true;
            this.cloneInProgress.push(rapport.id);
            await this.clone_report({
                project_id: this.getProjectId,
                modele_id: rapport.modele_id,
                rapport_id: rapport.id,
            }).then(() => {
                this.update_editing_reports_from_local_to_server();
                this.reload();
                remove(this.cloneInProgress, report_id => report_id === rapport.id);
            });
        },
    },
    computed: {
        can_deleted() {
            return (rapport) => includes(this.status_updatable, toUpper(rapport.format));
        },
        can_clone() {
            return (rapport) => includes(this.status_clonable, toUpper(rapport.format)) && rapport.header.clonable;
        },
        ...mapGetters("project", ["getProjectId"]),
        ...mapGetters("rapports", {
            in_progress: "get_in_progress",
        }),
    },
};
</script>