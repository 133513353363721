<template>
<a-locale-provider :locale="fr_FR">
 <div>
    <span v-if="title!=''">{{ title }} : </span>
        <a-date-picker v-if="!multiple"
            v-model="element"
            :format="dateFormat"
            :style='{"width": width}'
            :disabled="disabled"
        />
        <a-range-picker v-else
            v-model="element"
            :format="dateFormat"
            :disabled="disabled"
            :style='{"width": width}'
        />
    </div>
</a-locale-provider>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import {mapActions} from 'vuex'
import fr_FR from 'ant-design-vue/lib/locale-provider/fr_FR';
import 'moment/locale/fr';
moment.locale('fr');


export default {
    name: "DatePicker",
    props: {
        value: {
            required: true,
        },
        title:{
            required:false,
            default:""
        },
         width:{
            required:false,
            default:"130px"
        },
        dateFormat:{
            type:String,
            required:false,
            default:'DD/MM/YYYY'
        },
        multiple:{
            type:Boolean,
            required:false,
            default:false,
        },
         disabled:{
            type:Boolean,
            required:false,
            default:false
        }
    },
     data() {
      return {
        fr_FR,
      };
    },
    computed:{
        element:{
            get(){
                if(!this.multiple)
                    return moment(this.value,this.dateFormat)
                else
                    return [moment(this.value[0],this.dateFormat),moment(this.value[1],this.dateFormat)]

            },
            set(value){
                 if(!this.multiple){
                    this.$emit('input',value.format(this.dateFormat))
                 }else{
                    this.$emit('input',[value[0].format(this.dateFormat),value[1].format(this.dateFormat)])
                 }
                this.$emit('save')
            }
        }
    }
};
</script>

<style>
</style>