import { find, uniqBy, map, filter, includes } from 'lodash';

export default {
    get_rapports_to_display(state) {
        return state.rapports_to_display;
    },
    get_current_page(state) {
        return state.current_page;
    },
    get_user_rapport(state) {
        return state.user_rapport;
    },
    get_user_rapport_section_selected(state) {
        return state.user_rapport_section_selected;
    },
    has_last_report_values(state) {
        return (modele)=>{
            return find(modele.last_report_sections, ['instance_id', modele.detail_modele.current_instance.instance_id]);
        }
    },
    has_last_rapport_value(state){
        return (section_id)=>{
            let section=find(state.last_rapport_sections,['section_id',section_id]);
            if (section){
               return true;
            }
            return false;
        }
    },
    get_last_rapport_section_element_value(state){
        return (section_id,element_id)=>{
            let section=find(state.last_rapport_sections,['section_id',section_id]);
            if (section){
                let section_element=find(section.section_elements,['element_id',element_id]);
                if(section_element)
                    return section_element.element_values;
            }
            return [];
        }
    },
    get_last_rapport_section_name(state){
        return (section_id) => {
            let section = find(state.last_rapport_sections, ['section_id',section_id]);
            if (section) {
                return section.section_name;
            }

            return false;
        }
    },
    get_available_entreprise_ic(state){
        return state.available_entreprise_ic;
    },
    get_sections_rapport(state){
        return state.user_rapport.sections;
    },
    is_to_send_or_recap_report(state){
        return state.user_rapport.header.status_id==3 || state.user_rapport.header.status_id==4;
    },
    is_locked_report(state){
        return state.user_rapport.header.status_id>=1;
    },
    is_signed_report(state){
        return state.user_rapport.header.status_id==2;
    },
    is_to_send(state){
        return state.user_rapport.header.status_id==3
    },
    is_recap(state){
        return state.user_rapport.header.status_id==4
    },
    get_blocked_sign_error(state){
        return state.blocked_sign_error;
    },
    get_signatures_rapport(state){
        return state.user_rapport.header.signatures;
    },
    get_synchro_en_cours(state){
        return state.synchro_en_cours
    },
    get_header_rapport(state){
        return state.user_rapport.header;
    },
    get_entreprises_rapport(state, getters, rootState, rootGetters){
        return uniqBy(
            map(
                filter(rootGetters["project/getAllActors"],{'is_intervenant':false})
                ,(actor)=>{
            return {id:actor.org_id,text:actor.org_lib};}),"id");
    },
    get_intervenants_rapport(state, getters, rootState, rootGetters){
        return uniqBy(
            map(
                filter(rootGetters["project/getAllActors"],{'is_intervenant':true})
                ,(actor)=>{
            return {id:actor.org_id,text:actor.org_lib};}),"id");
    },
    get_open_send_interface(state){
        return state.send_interface;
    },
    get_open_sign_interface(state){
        return state.sign_interface;
    },
    get_open_preview_interface(state){
        return state.preview_interface
    },
    get_last_synchro(state){
        return state.last_synchro;
    },
    get_send_infos(state, getters, rootState,rootGetters){
        return {
            project:rootGetters["project/getProject"].lib,
            type:state.user_rapport.header.modele.description,
            sender_id:rootGetters["login/getConnectedUser"].id
        };
    },
    get_in_progress(state) {
        return state.in_progress;
    },
    report_is_syncing(state) {
        return (project_id, report_id) => {
            let reports_in_progress = find([ ...state.in_progress], (value) => {
                return value.project_id == project_id;
            });
            
            return reports_in_progress && includes(reports_in_progress.rapport, report_id);
        }
    }

};
