import {get,post} from '~/service/Common/service.js';
import _ from 'lodash'
import User_db from "~/db_access/User";

export default{
    
async  get_companies() {
    return new Promise(function (resolve) {
        get('user/companies', null, function (res) {
            resolve(res);
        }, false);
    });
},

async get_projects() {
    return new Promise(function (resolve) {
        get('actor/projects', null, async function (res) { 
            _.map(res,function (project){
                if(project.starred && User_db.is_favorite_project(project.id) ){
                    User_db.add_favorite_project(project.id)
                } else if(project.starred && User_db.is_favorite_project(project.id) ){
                    User_db.delete_favorite_project(project.id);
                }
            });           
            resolve(res);
        });
    });
},

async get_me() {
    console.log("test")
    return new Promise(function (resolve) {
        get('user/@me', null, function (res) {
            resolve(res);
        },false);
    });
},

async getActorConnected() {
    return new Promise(function (resolve) {
        get('actor/details', null, function (res) {
            resolve(res);
        });
    });
},


async update_favorite(project_id,is_starred){
    post('user/' + project_id + '/update_starred', {"is_starred":is_starred},
        function (res) {
            console.log(res);
        });
}

}