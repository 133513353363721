<template>
    <div class="connect-screen">
        <div>
            <a-form
                v-if="codeAlreadyDefine"
                class="login-form"
                :form="form"
                @submit="handleSubmit"
            >
                <a-form-item>
                    <a-input
                        v-decorator="[
                            'userName',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: this.getTranslation(
                                            'missingusername'
                                        ),
                                    },
                                ],
                            },
                        ]"
                        :placeholder="$t('connexion.username')"
                    >
                        <a-icon
                            slot="prefix"
                            type="user"
                            style="color: rgba(0, 0, 0, 0.25)"
                        />
                    </a-input>
                </a-form-item>
                <a-form-item v-if="getOnline">
                    <a-input
                        v-decorator="[
                            'password',
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: this.getTranslation(
                                            'missingpassword'
                                        ),
                                    },
                                ],
                            },
                        ]"
                        type="password"
                        :placeholder="$t('connexion.password')"
                    >
                        <a-icon
                            slot="prefix"
                            type="lock"
                            style="color: rgba(0, 0, 0, 0.25)"
                        />
                    </a-input>
                </a-form-item>
                <a-form-item v-else>
                    <NumberPad
                        :entete="'Code secret hors ligne'"
                        :value="code"
                    />
                </a-form-item>
                <a-form-item style="color: red">
                    <a-button
                        type="primary"
                        html-type="submit"
                        class="login-form-button"
                        >{{ $t("connexion.connexion") }}</a-button>
                </a-form-item>
                <div style="color:white;text-align:center">{{version}}</div>
                <div style="text-align:center">
                    <a-button 
                        v-if="false"
                        type="link"
                        @click="set_admin_modal_visible(true)"
                    >
                        Administration
                    </a-button>
                </div>
            </a-form>
            <div v-else>
                <NumberPad
                    style="background-color: rgb(2, 2, 2, 0.75)"
                    :isError="validation.codeNotAvailable"
                    :entete="
                        'Première connection à l\'adresse ' +
                        mailAdress +
                        '. Définissez votre code secret hors-ligne.'
                    "
                    :errorText="validation.errorTextCodeInvalide"
                    textBottom="*Le mot de passe ne doit pas être composé de 4 chiffres identiques et ne pas être trivial (ex: 1234)"
                    textButton="Valider"
                    v-model="code"
                    @input="validation=getValideCode(code)"
                    @get-code="saveCode"
                />
            </div>
        </div>
        <AdminModal />
    </div>
</template>

<script>
import Auth from "~/service/Common/Auth";
import User_API from "~/service/Common/User";
import NumberPad from "../common/NumberPad";
import AdminModal from "../common/AdminModal";
import User_db from "~/db_access/User";
import _ from "lodash";
 import Offline from '~/src/offline.js';

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "ConnexionForm",
    components: {
        NumberPad,
        AdminModal,
    },
    data() {
        return {
            errorConnexion: false,
            codeAlreadyDefine: true,
            validation:{},
            code: ["", "", "", ""],
            version:process.env.VUE_APP_VERSION,
        };
    },
    created() {
        this.setIsLoggedIn(false);
        this.form = this.$form.createForm(this, { name: "normal_login" });
        Offline.test_online(true);     
    },
    methods: {
        ...mapMutations(['set_admin_modal_visible']),
        ...mapActions(['changeOnline']),
        ...mapActions("login", ["setConnectedUser", "setIsLoggedIn"]),
        ...mapActions("company", ["updateCompanies"]),
        async saveCode(value) {
            await User_db.defineCode(value);
            this.codeAlreadyDefine = true;
            await this.updateCompanies();
            this.$router.push({ name: "CompanySelection" });
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    if (this.getOnline) {
                        Auth.login(values.userName, values.password).then(async (result)=>{
                            if(result){
                                let codeDefinedForUser = await User_db.user_has_code_defined(values.userName);
                                User_API.get_me().then(async (user)=>{
                                    if(!user){
                                        this.$notification["error"]({
                                            message: "Les informations de l'utilisateur n'ont pas pu être récupérée",
                                        });
                                    }
                                    else{
                                        this.setConnectedUser(user).then(async ()=>{
                                            if (!codeDefinedForUser) {
                                            this.mailAdress = values.userName;
                                            this.codeAlreadyDefine = false;
                                            }
                                            else 
                                            {
                                                await this.updateCompanies()
                                                this.$router.push({ name: "CompanySelection" , params:{alreadyLoaded:true}});
                                            }
                                        });  
                                    }
                                });
                                
                            } 
                            else {
                                this.$notification["error"]({
                                    message: "Mauvais couple login/mot de passe",
                                });
                            }
                        });
                    } else {
                        User_db.auth(values.userName,this.code).then(async (userConnected)=>{
                            if (userConnected) {
                                await this.setConnectedUser(userConnected);
                                await this.updateCompanies();
                                await this.setIsLoggedIn(true);
                                this.$router.push({ name: "CompanySelection" , params:{alreadyLoaded:true}});
                            } else {
                                this.$notification["error"]({
                                    message: "Mauvais couple login/code secret",
                                });
                            }
                        });     
                    }
                }
            });
        },
    },
    computed: {
        ...mapGetters(["getOnline" ,"getValideCode","getUpdate"]),
        ...mapGetters("login", { userConnected: "getConnectedUser" }),

        getTranslation() {
            return (key) => this.$t(`connexion.${key}`);
        },
    },
};
</script>