<template>
    <div class="container-tiles">
        <RapportModeleTile
            v-for="modele in modeles"
            :key="modele.id"
            :modele="modele"
        />
    </div>
</template>
<script>
import RapportModeleTile from "./RapportModeleTile.vue";
import Rapport_db from "~/db_access/Rapport";
import Rapport_API from "~/service/Rapport/Rapport";

export default {
    name: "AllModeleSelection",
    components: {
        RapportModeleTile,
    },
    data() {
        return {
            modeles: [],
        };
    },
    async beforeMount() {
        this.modeles = await Rapport_db.get_all_modeles();
        if (this.modeles.length === 0) {
            this.modeles = await Rapport_API.get_all_modeles();
            Rapport_db.register_modeles_rapports(this.modeles);
        }
    },
};
</script>