export default {
    rapports_to_display: [],
    current_page: 1,
    user_rapport: {},
    user_rapport_section_selected: {},
    synchro_en_cours: false,
    last_rapport_sections: [],
    available_entreprise_ic: [],
    blocked_sign_error: [],
    locked_report: false,
    preview_interface: false,
    sign_interface: false,
    send_interface: false,
    last_synchro: {
        date: "",
        success: ""
    },
    in_progress: [],
    create_report: false,
};