<template>
  <div class="connect-modal">
    <div v-if="actor.name !== undefined">
      {{ $t('applayout.connected_as') }} {{ userConnected.email }}
    </div>
    <div  v-if="showCodePad">
    <NumberPad
        style="margin-top:20px"
        :isError="validation.codeNotAvailable"
        :errorText="validation.errorTextCodeInvalide"
        textBottom="*Le mot de passe doit être composé de 4 chiffres identiques et ne pas être trivial (ex: 1234)"
        textButton="Valider"
        v-model="code"
        @get-code="saveCode"
        @input="validation=getValideCode(code)"
    />
    </div>
    <a-button v-else class="button" @click="chooseCode"
      >{{ $t('applayout.change_pin_code') }}</a-button
    >
    <a-button v-if="getActiveModule === 'RAPPORT'" class="button" @click="get_data_report"
      >Récupérer rapport d'erreur</a-button
    >
    <a-button class="button" @click="exportDb"
      >Exporter mes données</a-button
    >
    <a-button v-if="getActiveModule === 'RAPPORT'" class="button" @click="force_offline"
      >{{ getOfflineTranslation }}</a-button
    >
    <a-button class="button" @click="disconnect"
      >{{ $t('applayout.disconnect') }}</a-button
    >
    <a-modal class="pwa-modal"
      v-model="erreurModal"
      :closable="true"
      :footer="null"
      :centered="true"
      title="Rapport d'erreur"
      width="350px"
    >
      <!-- <a-input style='min-height: 300px;' type="textarea" :value="localReportData"></a-input> -->
      <span>{{ localReportData }}</span>
    </a-modal>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import NumberPad from '../common/NumberPad'
import _ from 'lodash'
import Auth from "~/service/Common/Auth";
import User_db from "~/db_access/User";
import Rapport_db from "~/db_access/Rapport";
import Offline from '~/src/offline.js';

export default {
   name: "ConnectModal",
  components: {
    NumberPad
  },
  props:{
    display:{
      type:Boolean,
      required:false,
    }
  },
  watch:{
    display:function(value){
      if(!value)
      this.showCodePad=false;
    }
  },
  data() {
    return {
      showCodePad: false,
      code: ["", "", "", ""],
      validation:{},
      localReportData:"",
      erreurModal:false,
    };
  },
  deactivated(){
    this.showCodePad=false;
  },
  methods: {
    ...mapActions([
        'exportDb',
    ]),
    ...mapMutations([
        'CHANGE_FORCE_OFFLINE',
    ]),
    disconnect() {
      Auth.logout();
      this.$router.push({ name: "Connexion" });
    },
    chooseCode() {
      this.showCodePad = true;
    },
    async saveCode(value) {
      await User_db.defineCode(value);
      this.showCodePad = false;
      this.$notification["success"]({
        message: "Changement de votre code secret",
        description: "Votre nouveau code secret a été défini avec succès",
        duration: 2,
      });
    },
    async get_data_report() {
        await Rapport_db.get_unsynchronised_sections().then(async (unsynchronised_sections) => {
          this.localReportData  = 'Synchro en cours : '+this.get_synchro_en_cours;
          this.localReportData += '<br>Rapports en cours de synchro : '+JSON.stringify(this.get_in_progress);
          //this.localReportData += '<br>Rapports à synchro : '+JSON.stringify(_.groupBy(unsynchronised_sections, 'rapport_id'));
          console.log(_.groupBy(unsynchronised_sections, 'rapport_id'));
        });
        this.erreurModal = true;
    },
    force_offline() {
        this.CHANGE_FORCE_OFFLINE(!this.getForceOffline);
        Offline.test_online();
    },

  },
  computed: {
    ...mapGetters(["getValideCode", "getActiveModule", "getForceOffline"]),
    ...mapGetters("login", { actor: "getConnectedActor",userConnected:"getConnectedUser" }),
    ...mapGetters("rapports", ['get_synchro_en_cours', 'get_in_progress']),
    getOfflineTranslation() {
      return this.getForceOffline ? this.$t('applayout.force_onfline') : this.$t('applayout.force_offline');
    }
  },
};
</script>
