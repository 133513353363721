<template>
    <div class="pwa-screen">
        <a-layout>
            <a-layout-header class="layout-header" :class="$mq">
                <Header
                    :displayLogoButton="true"
                    :displayBackButton="true"
                    :displaySearchBar="true"
                    :displayTeamButton="true"
                    :displayOfflineButton="true"
                    :displayModuleButton="true"
                    :title="project.lib"
                />
            </a-layout-header>
            <a-layout-content>
                <AllRapportSelection />
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script>
import Header from "./applayout/Header";
import AllRapportSelection from "./rapportselection/AllRapportSelection";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "FileModule",
    components: {
        Header,
        AllRapportSelection,
    },
    data() {
        return {
            collapsed: true,
        };
    },
    created() {
        if (!this.$route.params.alreadyLoaded) {
            this.display_reports_by_state(this.display_state);
        }
    },
    methods: {
        ...mapActions("rapports", ["display_reports_by_state"]),
    },
    computed: {
        ...mapGetters({
            online: "getOnline",
            module: "getActiveModule",
            display_state: "getDisplayState",
        }),
        ...mapGetters("project", { project: "getProject" }),
    },
};
</script>



