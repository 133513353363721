<template>
	<div>
		<div class="header" :class="$mq">
		<div class="gauche">
			<img
				:class="$mq"
				v-if="displayBackButton"
				src="../../assets/back.png"
				alt="BACK"
				@click="goBack"
			/>
		</div>
		<div class="centre">
			<span class="text" :class="$mq">
				{{ title }}
				<sup><a-icon type="question-circle" @click="helpInformations" /></sup>
			</span>
		</div>
		<div class="droite" :class="$mq">
			<img
				v-show="displayModuleButtonActive"
				src="../../assets/modules.png"
				alt="MODULE"
				@click="showModuleModal"
			/>			
			<img
				:class="$mq"
				src="../../assets/userLogo.png"
				alt="USER"
				@click="showConnectModal"
			/>
			<img
				v-show="displayTeamButton"
				:class="$mq"
				src="../../assets/team.png"
				alt="TEAM"
				@click="showTeamModal"
			/>
			<img v-if="updateAvailable"
				:class="$mq"
				src="../../assets/update_white.png"
				alt="UPD"
				@click="launchUpdate"
			/>
			<a-modal           
				v-model="moduleModal"
				:closable="true"
				:footer="null"
				:centered="true"
				width="50vw"
			>
				<SelectModuleModal @exit="exitmodalModule" />
			</a-modal>

			<a-modal class="pwa-modal"
				v-model="teamModal"
				:closable="true"
				:footer="null"
				:centered="true"
				title="Equipe Projet"
				width="70vw"
			>
				<TeamModal />
			</a-modal>

			<a-modal class="pwa-modal"
				v-model="connectModal"
				:closable="true"
				:footer="null"
				:centered="true"
				title="Profil"
				width="350px"
			>
				<ConnectModal :display="connectModal"/>
			</a-modal>
		</div>
		</div>
			<div v-if="online && displayStockagePercent && stockage!='Err'" class="stockage-quota">
				{{stockage}} % du stockage utilisé
			</div>
		<div v-if="!online" class="alert-offline">
			Hors ligne
		</div>  
	</div>
</template>
<script>
import SelectModuleModal from "./SelectModuleModal.vue";
import ConnectModal from "./ConnectModal.vue";
import TeamModal from "./TeamModal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
	name: "Header",
	components: {
		SelectModuleModal,
		TeamModal,
		ConnectModal,
	},
	data() {
		return {
			moduleModal: false,
			teamModal: false,
			connectModal: false,
		};
	},
	methods: {
		...mapActions(["loadUpdate", "addInterruptCurrentLoading","changeLastRouteName"]),
		...mapActions("rapports",["save_section","go_back_edition"]),
		helpInformations() {
			window.open('https://drive.google.com/drive/folders/1qUyViuOpVBjrqz1POfn4l4rjvXUisGVs', '_blank');
		},
		showModuleModal() {
			this.moduleModal = true;
		},
		showConnectModal() {
			this.connectModal = true;
		},
		showTeamModal() {
			this.$router.push({ name: "Actors"});
		},
		exitmodalModule() {
			this.moduleModal = false;
		},
		connected() {
			this.$notification['info']({
				message: 'Connexion établie',
				description: "La connexion est établie, vous êtes en ligne.",
			});
		},
		unConnected() {
			this.$notification['warning']({
				message: 'Connexion perdue',
				description: "La connexion a été perdue, vous êtes hors-ligne.",
			});
		},
		async goBack() {
			this.changeLastRouteName(this.$router.currentRoute.name);
			switch (this.$router.currentRoute.name) {
				case 'EditRapport':
					if (!this.preview && !this.send && !this.sign) {
						this.$router.push({ name: "Rapport" , params:{alreadyLoaded:false}});
					} else {
						this.go_back_edition();
					}
					break;
				case 'Rapport':
					this.$router.push({ name: "EspaceSelection" , params:{alreadyLoaded:false}});
					break;
				case 'File':
					this.$router.push({ name: "EspaceSelection" , params:{alreadyLoaded:false}});
					break; 
				case 'EspaceSelection':
					this.$router.push({ name: "CompanySelection" , params:{alreadyLoaded:false}});
					break;  
				case 'CompanySelection':
					this.$router.push({ name: "Connexion" });
					break; 
				case 'Viewer':
					if (this.getViewableFileId) {
						this.addInterruptCurrentLoading(this.getViewableFileId);
					}
					this.$router.go(-1);
					break;		
				default:
					this.$router.go(-1);
					break;
			}
		},
		async launchUpdate() {
			try {
				await this.loadUpdate();        
			} catch (error) {
				this.$notification["error"]({
					message: "Ereur de mise à jour ",
					description: "La mise à jour à rencontrer un problème.",
				});
			}
		},
	},
	computed: {
		...mapGetters(["getViewableFileId"]),
		...mapGetters({ online: "getOnline", updateAvailable: "getUpdateAvailable" }),
		...mapGetters("project", { project: "getProject" }),
		...mapGetters("login", { actor: "getConnectedActor" }),
		...mapGetters("rapports", { preview: "get_open_preview_interface",send: "get_open_send_interface",sign: "get_open_sign_interface" }),
		...mapGetters("login", { actor: "getConnectedActor" }),
		...mapGetters({stockage:"getStockagePercent"}),
		displayModuleButtonActive() {
			return (
				this.displayModuleButton &&
				this.project.gedModuleActif &&
				this.project.rapportModuleActif
			);
		},
	},
	props: {
		displaySearchBar: {
			type: Boolean,
			default: false,
		},
		displayLogoButton: {
			type: Boolean,
			default: false,
		},
		displayBackButton: {
			type: Boolean,
			default: false,
		},
		displayStockagePercent: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String,
			default: "",
		},
		displayTeamButton: {
			type: Boolean,
			default: false,
		},
		displayModuleButton: {
			type: Boolean,
			default: false,
		},
		displayOfflineButton: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
