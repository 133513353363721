<template>
<span class="tile">
    <a-card hoverable>
      <div class="tile-img" slot="cover" @click="onSelect">
		<div class="icon-offline" :style="{'background-color':espace.isOffline?'green':'red'}" @click="e=>infoInitialisation(e)" />
			<img v-if="espace.projectImg != undefined" alt :src="espace.projectImg" />
			<img v-else alt src="../../assets/no_image.png" />
		</div>
		<a-card-meta>
			<template slot="title">
			<div :style="{'visibility':loadingEspace?'visible':'hidden'}">
				<a-progress style="width: 80%" :percent="loadingInitPercent" :show-info="false"/>
			</div>
				<img
				v-if="favorite!==undefined && favorite"
				class="favoriteIcon"
				alt
				src="../../assets/favorites.png"
				@click="favorite=false"
			/>
			<img
				v-if="favorite!==undefined && !favorite"
				class="favoriteIcon"
				alt
				src="../../assets/nofavorites.png"
				@click="favorite=true"
			/>
			<div class="title" :style="policeSizeProject">{{ espace.lib }}</div>
			</template>
		</a-card-meta>
	</a-card>
</span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import User_db from "~/db_access/User";
import User_API from "~/service/Common/User";

export default {
	name: "EspaceTile",
	props: {
		value:{
			required:true,
		},
		espace: {
			required: true,
		},
		selectEspaceAvailable:{
			required:false,
		default:true
		},
		rapportModuleActif:{
			required:false,
			default:process.env.VUE_APP_MODULE_RAPPORT_ACTIF
		},
		gedModuleActif:{
			required:true,
			default:process.env.VUE_APP_MODULE_GED_ACTIF
		}
	},
	data() {
		return {
		reload: false,
		loadingEspace: false,
		};
	},
	computed: {
		...mapGetters({ loadingInitPercent: "getLoadingInitPercent",online:"getOnline" }),
		policeSizeProject() {
			let length = this.espace.lib.length;
			let fontSize = 12;
			if (length < 20) {
				fontSize = 16;
			} else if (length < 30) {
				fontSize = 14;
			}

			return `font-size:${fontSize}px;`;
		},
		favorite:{
				get(){
					return this.value;
				},
				set(value){
				if(value){
					User_db.add_favorite_project(this.espace.id);
					User_API.update_favorite(this.espace.id,true);
				}
				else{
					User_db.delete_favorite_project(this.espace.id);
					User_API.update_favorite(this.espace.id,false);
				}
					this.$emit('input',value)
				}
		}
	},
	watch:{
		espace:function(value){
		this.favoris= this.espace.starred;
		}
	},
	methods: {
		...mapActions([
			"changeActiveModule",
			"init_with_online_files",
			"init_with_offline_files",
			"init_with_online_reports",
			"init_with_offline_reports",
		]),
		...mapActions("project", ["setProject"]),
		...mapActions(["changeInitLoadingPercent"]),
		infoInitialisation(event){
			if(this.espace.isOffline){
				this.$notification["success"]({
					message: "Le projet a été initialité et est disponible hors-ligne ",
				});
			}else{
				this.$notification["warning"]({
					message: "Le projet n'a j'amais été initialisé et ne sera donc pas disponible hors-ligne ",
				});
			}
				event.stopPropagation()
		},
		async onSelect() {
			if(!this.selectEspaceAvailable) {
				return;
			}
			this.$emit('espace-select-event', true)
			await this.setProject(this.espace);
			if (this.gedModuleActif) {
				this.changeActiveModule("GED")
				this.loadingEspace = true;
				if (this.online) {
					await this.init_with_online_files();
				} else {
					await this.init_with_offline_files();
				}
				setTimeout(() => {
					this.loadingEspace = false;
					this.$router.push({ name: "File" });
					this.$emit('espace-select-event', true)
				}, 500);
			} else if (this.rapportModuleActif) {
				this.changeActiveModule("RAPPORT");
				this.loadingEspace = true;
				let go = function (){
					this.loadingEspace = false;
					this.$router.push({ name: "Rapport" });
					this.$emit('espace-select-event', true)
				}.bind(this);
				if (this.online) {
					await this.init_with_online_reports();
				} else {
					await this.init_with_offline_reports();
				}
				setTimeout(() => {
					this.loadingEspace = false;
					this.$router.push({ name: "Rapport", params:{alreadyLoaded:true}});
					this.$emit('espace-select-event', true)
				}, 500);

			}
		},
	},
};
</script>
