import Vue from 'vue';
Vue.config.devtools = true
import App from './App.vue';
import Antd from 'ant-design-vue';

import store from "./store/";
import 'ant-design-vue/dist/antd.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import './CustomQuillColor';
import router from '~/src/router';
import i18next from './i18n';
import VueI18Next from '@panter/vue-i18next';
import AsyncComputed from 'vue-async-computed';
import VueSignaturePad from 'vue-signature-pad';
import VueObserveVisibility from 'vue-observe-visibility';
import VueScrollTo from 'vue-scrollto';
import VueMq from 'vue-mq';
// import './offline';
import './synchro';
import './sass/settings.scss';
import './sass/components.scss';
import {Workbox} from 'workbox-window';

Vue.use(VueObserveVisibility)
Vue.use(VueSignaturePad);
Vue.use(Antd);
Vue.use(VueI18Next);
Vue.use(AsyncComputed);
Vue.use(VueScrollTo);
Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity,
  }
})

const i18n = new VueI18Next(i18next);

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
}).$mount('#app');


if ('serviceWorker' in navigator) {
  const wb = new Workbox('/service-worker.js');


  wb.addEventListener('installed', (event) => {
    if (event.isUpdate) {
      store.dispatch("changeUpdate",{type:'UPDATE',value:wb})
    }
  });

  wb.addEventListener('activated', async (event) => {
      store.dispatch("notifUpdate");
  });

  wb.register()

}

    router.beforeEach((to, from, next) => {
      //redirect to login if not connected
      if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters['login/getIsLoggedIn']) {
          next({ name: 'Connexion' })
        } else {
          next()
        }
      } else {
        next()
      }
    })
