import _ from 'lodash';

export default {
    UPDATE_RAPPORTS_TO_DISPLAY(state, rapports) {
        state.rapports_to_display = rapports;
    },
    CHANGE_USER_RAPPORT(state, rapport) {
        state.user_rapport = rapport;
    },
    CHANGE_LAST_RAPPORT_SECTIONS(state, sections) {
        state.last_rapport_sections = sections;
    },
    CHANGE_AVAILABLE_ENTREPRISE_IC(state, sections) {
        state.available_entreprise_ic = sections;
    },
    CHANGE_USER_RAPPORT_SECTION_SELECTED(state, section) {
        state.user_rapport_section_selected = section;
    },
    CHANGE_USER_RAPPORT_STATUT(state, value) {
        state.user_rapport.header.status_id = value;
    },
    CHANGE_USER_RAPPORT_TITLE(state, value) {
        state.user_rapport.header.modele.description = value;
    },
    CHANGE_USER_RAPPORT_TEXT_MAIL(state, value) {
        state.user_rapport.header.text_mail = value;
    },
    CHANGE_USER_RAPPORT_OBJECT_MAIL(state, value) {
        state.user_rapport.header.object_mail = value;
    },
    CHANGE_USER_RAPPORT_SEND_INFOS(state, value) {
        state.user_rapport.header.send_infos = value;
    },
    CHANGE_USER_RAPPORT_ID(state, new_id) {
        state.user_rapport.header.rapport_id = new_id;
        _.map(state.user_rapport.sections, (section) => {
            section.rapport_id = new_id;
        })
    },
    UPDATE_SIGNATURE(state, { type, value, img }) {
        let signature = _.find(state.user_rapport.header.signatures, ['type', type])
        if (signature) {
            signature.image = img;
            signature.value = value;
        }
    },
    RESET_VISAS(state) {
        _.map(state.user_rapport.header.signatures, (signature) => {
            signature.value = [];
            signature.image = { isEmpty: true, data: "" }
        });
    },

    CHANGE_SYNCHRO_EN_COURS(state, value) {
        state.synchro_en_cours = value;
    },
    ADD_BLOCKED_SIGN_ERROR(state, error) {
        state.blocked_sign_error.push(error)
    },
    REMOVE_BLOCKED_SIGN_ERROR(state, error_id) {
        state.blocked_sign_error = [..._.filter(state.blocked_sign_error,(error)=>error.id!=error_id)];
    },
    RESET_BLOCKED_SIGN_ERROR(state) {
        state.blocked_sign_error = []
    },
    CHANGE_SIGN_INTERFACE(state, value) {
        state.sign_interface = value;
    },
    CHANGE_PREVIEW_INTERFACE(state, value) {
        state.preview_interface = value;
    },
    CHANGE_SEND_INTERFACE(state, value) {
        state.send_interface = value
    },
    CHANGE_LAST_SYNCHRO(state, value) {
        state.last_synchro = value;
    },
    CHANGE_LOCKED_REPORT(state, value) {
        state.locked_report = value;
    },
    DELETE_OFFLINE_VALIDATE_RAPPORT_TO_DISPLAY(state, file) {
        state.rapports_to_display = _.reject(state.rapports_to_display, { id: file.id });
    },
    CHANGE_IN_PROGRESS(state, value) {
        state.in_progress = value;
    },
    CHANGE_CREATE_REPORT_IN_PROGRESS(state, value) {
        state.create_report = value;
    },
    REMOVE_IN_PROGRESS(state, { project_id, id  }) {
        const in_progress = _.map(state.in_progress,(progress)=>{
            if(progress.project_id==project_id)
                return {...progress,rapport:_.filter(progress.rapport,(rapport)=>rapport!=id)}
            return {...progress}
        });
        state.in_progress = _.filter(in_progress, (progress) => progress.rapport.length > 0);
    },
    CHANGE_ID_IN_LIST_RAPPORT_DISPLAY(state, { old, new_id }) {
        state.rapports_to_display = _.map(state.rapports_to_display,
            (rapport) => rapport.id == old ? { ...rapport, id: new_id } : rapport);
    },
    CHANGE_SECTION_ELEMENTS_VALUES(state,{ section_id, element_id, element_values }){
        console.log("CHANGE_SECTION_ELEMENTS_VALUES")
        state.user_rapport={...state.user_rapport,
            sections:_.map(state.user_rapport.sections,(section)=>{
                if(section.section_id===section_id){
                    console.log("section trouvée",section)
                    return {...section,
                        section_elements:_.map(section.section_elements,(section_element)=>{
                        if(section_element.element_id===element_id){
                            console.log("element trouvée")
                            return {...section_element,element_values}
                        }
                        return{...section_element}
                        })
                    }
                }
                return {...section}
            })
        }
        console.log(state.user_rapport);
    }
};